import React from "react"
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../assets/css/styles.css'
import axios from 'axios'
import logoSrc from "../assets/img/lokk_logo.svg"
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import { ReactComponent as OfficeDraw } from "./../assets/img/Office-draw.svg"

class PinSubmitOffice extends React.Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = this.props.recaptchaRef
    this.state = {
      phoneNumber: '',
      pin: '',
      pinRegex: new RegExp('^[0-9]{4}$'),
      phoneNumberRegex: new RegExp('^[0-9]{9}$'),
      showOpenDoorModal: false,
      showConfirmationModal: false,
      doorNumber: null,
      buttonDisabled: true,
    }
  }

  handlePin = (e) => {
    this.setState({ pin: e.target.value }, () => {
      this.confirmButtonDisabled()
    })
  }

  handlePhoneNumber = (e) => {
    this.setState({ phoneNumber: e.target.value }, () => {
      //this.confirmButtonDisabled()
    })
  }
  confirmButtonDisabled = () => {
    if (this.state.pin.length <= 0
    ) {
      this.setState({ buttonDisabled: true })
    } else {
      this.setState({ buttonDisabled: false })
    }
  }

  openDoor = () => {
    axios.get(`${process.env.REACT_APP_API_URL}api/delivery/pickUpPackageWithQr?pin=${this.state.pin}&recipientPhone=${this.state.phoneNumber}`, {
      headers: {
        Authorization: 'Bearer ' + process.env.REACT_APP_API_TOKEN
      }
    })
      .then(res => {
        if (res.data.ResultCode == 1) {
          this.setState({ pin: '', recipientPhone: '' })
          this.setState({ showOpenDoorModal: false })
          this.setState({ showConfirmationModal: true })
        } else {
          toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 1 })
        }
      })
      .catch((err) => {
        toast.error("Ocorreu um erro. Por favor tente novamente.", { toastId: 2 })
      })
  }

  onSubmit = () => {
    if (this.state.pin.match(this.state.pinRegex)) {
      this.recaptchaRef.current.reset()
      this.recaptchaRef.current.executeAsync().then(token => {
        let my_captcha_response = window.grecaptcha.getResponse()
        if (my_captcha_response) {
          const id = new URLSearchParams(window.location.search).get('Id')
          let pin = this.state.pin
          axios.get(`${process.env.REACT_APP_API_URL}julian/read-pin?Id=${id}&Pin=${pin}`)
            .then(res => {
              console.log(res)
              if (res.data.ResultCode == 1) {
                toast.success("Your locker in this location has been openened!", { toastId: 3 })
              } else {
                toast.error("There was an error opening the locker. Are you sure that's the correct PIN?.", { toastId: 4 })
              }
            })
            .catch((err) => {
              toast.error("There was an error opening the locker. Are you sure that's the correct PIN?.", { toastId: 5 })
            })
        } else {
          toast.warning("Por favor complete o CAPTCHA!", { toastId: 6 })
        }
      })
    } else {
      toast.error("Por favor certifique-se que os dados inseridos são válidos!", { toastId: 7 })
    }
  }

  toggleOpenDoorModal = () => {
    this.setState({ showOpenDoorModal: !this.state.showOpenDoorModal })
  }

  toggleConfirmationModal = () => {
    this.setState({ showConfirmationModal: !this.state.showConfirmationModal })
  }

  render() {
    const { showOpenDoorModal, showConfirmationModal } = this.state

    const openDoorModal = (
      <Modal isOpen={showOpenDoorModal} toggle={this.toggleOpenDoorModal} centered size="xl" className="open-door-modal">
        <ModalHeader className="modal-bg" toggle={this.toggleOpenDoorModal}><span className="modal-title">Código Confirmado</span></ModalHeader>
        <ModalBody className="modal-body modal-size">
          <p>A sua encomenda encontra-se na porta
            <span className="door-number door"> {this.state.doorNumber}</span>
            .
          </p>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => this.openDoor()}>Abrir porta</Button>
        </ModalFooter>
      </Modal>
    )

    const confirmationModal = (
      <Modal isOpen={showConfirmationModal} toggle={this.toggleConfirmationModal} className="confirm-door-open-modal" centered size="xl">
        <ModalHeader className="modal-bg" toggle={this.toggleConfirmationModal}>
          <span className="modal-title">Porta aberta!</span>
        </ModalHeader>
        <ModalBody className="modal-body modal-size">
          <p>Obrigado pela preferência!</p>
        </ModalBody>
      </Modal>
    )

    return (
      <Row className="m-0 justify-content-center submit-office-row">
        {openDoorModal}
        {confirmationModal}
        <Col className="col-12 p-0 main-col justify-content-center">
          <Row className="m-0 align-items-center main-row">
            <Col className="p-0 col-12">
              <Form>
                <Col className="m-0 p-0">
                  <Row className="justify-content-center m-0">
                    <Col className="col-12 p-0">
                      <h4 className="text-center title">Abrir cacifo</h4>
                      <p className="text-center text-muted">Insira o seu PIN para abrir o seu cacifo</p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center office-draw">
                    <OfficeDraw />
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <FormGroup>
                        <Input
                          type="password"
                          name="pin"
                          id="pin"
                          placeholder="PIN"
                          onChange={(e) => this.handlePin(e)}
                          value={this.state.pin}
                          pattern="[0-9]*" />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-12 p-0">
                      <FormGroup>
                        <Button className="btn" disabled={this.state.buttonDisabled ? true : false} block onClick={this.onSubmit}>Confirmar</Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}
export default PinSubmitOffice
